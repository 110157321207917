import React from "react"
import { Link } from "gatsby"
import { FaTwitter, FaLinkedin, FaInstagram, FaEnvelope } from "react-icons/fa"
import logo from '../images/logo.svg'
import { Navbar, Nav, Container } from "react-bootstrap"

const CustomNavbar = ({ pageInfo }) => {
  //console.log(pageInfo)
  return (
    <>
      <Navbar variant="light" expand="md" id="site-navbar">
        <Container> 
        <Navbar.Brand href="/">
        <img
          alt=""
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />
        CoinMarketMood
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        
          <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
            <Link to="/" className="link-no-style">
              <Nav.Link as="span" eventKey="rankings">
              Rankings
              </Nav.Link>
            </Link>
            <Link to="/about/" className="link-no-style">
              <Nav.Link as="span" eventKey="about">
                About
              </Nav.Link>
            </Link>
            <Link to="/services/" className="link-no-style">
              <Nav.Link as="span" eventKey="services">
                Services
              </Nav.Link>
            </Link>
            <Link to="/blog/" className="link-no-style">
              <Nav.Link as="span" eventKey="blog">
                Blog
              </Nav.Link>
            </Link>
            <Link to="/team/" className="link-no-style">
              <Nav.Link as="span" eventKey="team">
                Team
              </Nav.Link>
            </Link>
            <Link to="/sponsors/" className="link-no-style">
              <Nav.Link as="span" eventKey="sponsors">
                Sponsors
              </Nav.Link>
            </Link>
          </Nav>
          <Nav className="ml-auto">
            <a className="link-no-style" target='_blank' href='https://twitter.com/coinmarketmood' rel="noopener noreferrer" className='m-2'>
              <FaTwitter size={22} color="#000000" />
            </a>
            <a className="link-no-style" target='_blank' href='https://www.linkedin.com/company/coin-market-mood' rel="noopener noreferrer" className='m-2'>
              <FaLinkedin size={22} color="#000000" />
            </a>
            <a className="link-no-style" target='_blank' href='https://instagram.com/coinmarketmood' rel="noopener noreferrer" className='m-2'>
              <FaInstagram size={22} color="#000000" />
            </a>
            <a className="link-no-style" target='_blank' href='mailto:contact@coinmarketmood.com' rel="noopener noreferrer" className='m-2'>
              <FaEnvelope size={22} color="#000000" />
            </a>
            {/*<Form inline onSubmit={e => e.preventDefault()}>
              <Form.Group>
                <FormControl
                  type="text"
                  placeholder="Fake Search"
                  className="mr-2"
                />
              </Form.Group>
              <Button>Fake Button</Button>
            </Form>*/}
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default CustomNavbar
