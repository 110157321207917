import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ pageName }) => (
  <header
    style={{
      background: `#20232a`,
      //backgroundImage: `url(${gradient})`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `100%`
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0.24rem 0.0875rem`,
        textAlign: `center`
      }}
    >
      <h3 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
        </Link>
      </h3>
    </div>
  </header>
)

Header.propTypes = {
  pageName: PropTypes.string,
}

Header.defaultProps = {
  pageName: ``,
}

export default Header
